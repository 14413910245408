import { Button, CardBody } from "reactstrap";
import { logoutUser } from "../../routes/AuthorizeContext";
import PostSignup from "../../components/PostSignup";
import VerifyEmailIcon from "../../assets/img/auth/VerifyEmail.svg?react";
import { useLocation } from "react-router-dom";
import { prependApiPrefix } from "../../api/prependApiPrefix";
import { ToastrSuccess } from "../../services/toastrService";

const VerifyEmail = () => {
  const location = useLocation();

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  let signUpFormValues: any = {};

  if (urlParams.has("aid") && urlParams.has("e")) {
    signUpFormValues = {
      id: urlParams.get("aid"),
      contactEmail: urlParams.get("e"),
    };
  } else {
    signUpFormValues = (location.state as any)?.signUpFormValues;
  }

  const resendEmailVerification = async () => {
    const data = {
      AccountId: signUpFormValues?.id,
      Email: signUpFormValues?.contactEmail,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const url = `/users/loginEmail/resend`;

    const response = await fetch(prependApiPrefix(url), requestOptions);

    if (response.ok) {
      ToastrSuccess(
        "Verification Email Resent Successfully!",
        "A new verification email has been sent to your registered email address.",
      );
    }
  };

  return (
    <div className="pages-auth text-center">
      <CardBody>
        <VerifyEmailIcon />
        <div className="m-sm-4">
          <h1 className="mb-3">Please Verify Your Email</h1>
          <p className="mb-4">
            Please check your email for a verification link to confirm your
            address. If the email is not in your inbox, be sure to check your
            spam or junk folders.
          </p>
          <div className="text-center button-container">
            <Button
              color="primary"
              tag="a"
              onClick={async () => resendEmailVerification()}
              className="align-verification"
              data-testId="button-auth-resend-verification"
            >
              <i className="fa fa-solid fa-paper-plane align-middle me-2 no-pointer-event" />
              Resend Verification
            </Button>
            <Button
              color="primary"
              tag="a"
              onClick={() => logoutUser()}
              className="logout-button tertiary-button btn-secondary"
              data-testid="button-logout"
            >
              <i className="fa fa-sign-out-alt align-middle me-2 no-pointer-event" />
              Sign out
            </Button>
          </div>
          <PostSignup />
        </div>
      </CardBody>
    </div>
  );
};

export default VerifyEmail;

import { cdataFetch } from "src/api/cdataFetch";
import { IPartnerMetric } from "src/models";

export function getPartnerMetricById(id: string): Promise<IPartnerMetric> {
  return cdataFetch<IPartnerMetric>({
    method: "GET",
    url: `/partner/metric/${id}`,
    skipAuthentication: true,
  });
}
